@use "typography";

.fw-semi-bold {
  font-weight: typography.$font-weight-semi-bold;
}

.highlight-matches em {
  background: #eecc00;
  color: white;
  font-weight: bold;
  font-style: normal;
  padding: 0 5px;
}

.break-word {
  word-break: break-word;
}


