@use 'abstracts/colors';

.mat-mdc-form-field-flex {
  align-items: center !important;
}

app-date-range-picker {

  .mat-hidden-arrow .mat-mdc-select-arrow {
    border: none !important;
  }

  .mat-mdc-form-field-infix {
    min-height: 50px !important;
  }

  .mat-mdc-select-arrow-wrapper {
    display: none;
  }
}

.mat-form-field-appearance-fill .mat-mdc-form-field-infix input::placeholder {
    user-select: initial !important;
    color: rgba(0, 0, 0, 0.42) !important;
    -webkit-text-fill-color: initial !important;
    transition: initial !important;
}

.mat-calendar-body-today {
  background-color: colors.$white !important;

  &:hover {
    background-color: colors.$black !important;
  }
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-calendar-body-in-range::before,
.mat-calendar-body-selected {
  background-color: colors.$black !important;
}

.mat-calendar-body-cell-container .mat-calendar-body-disabled {
  opacity: 0.6;
  color: colors.$grey-900 !important;

  &:hover .mat-calendar-body-cell-content {
    color: colors.$grey-900 !important;
  }
}

.mat-datepicker-toggle .mat-mdc-icon-button {
  padding: 12px 2px !important;
}

.mat-datepicker-toggle-default-icon {
  height: 22px !important;
}

.mat-calendar-body-in-range .mat-calendar-body-cell-content {
  color: colors.$yellow-500 !important;
}

.mat-calendar-body-cell:hover {
  .mat-calendar-body-cell-content {
    color: colors.$yellow-500 !important;
  }
}

.mat-calendar-body-cell-content {
  border-width: 0 !important;

  &.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px colors.$yellow-500;
    color: colors.$yellow-500 !important;
  }
}

mat-datepicker-toggle {
  .mat-mdc-button-persistent-ripple, .mat-button-focus-overlay, .mat-mdc-button-touch-target {
    display: none !important;
  }

  .mat-mdc-icon-button {
    width: unset !important;
    padding: 0 !important;
  }
}

mat-timepicker-toggle, mat-datepicker-toggle {
  .mat-mdc-button-ripple, .mat-mdc-button-persistent-ripple, .mat-mdc-button-touch-target, .mat-focus-indicator {
    display: none !important;
  }
}

.mat-datepicker-content {
  background-color: white;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;

  .mat-button-disabled {
    color: colors.$grey-500;
    border-color: colors.$grey-500;
  }
}

mat-timepicker-toggle {
  color: var(--mat-datepicker-toggle-icon-color, var(--mat-sys-on-surface-variant));
}

mat-timepicker-toggle > button[aria-expanded="true"] {
  color: var(--mat-datepicker-toggle-active-state-icon-color, var(--mat-sys-on-surface-variant));
}

.date-picker-field.mat-form-field-disabled {
  .mat-mdc-notch-piece, .mat-mdc-notch-piece {
    border: unset !important;
  }
}
