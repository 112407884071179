@use "abstracts/colors";

.account-search {
  .mat-mdc {
    &-form-field {
      &-outline {
        height: 40px !important;
        border-radius: 20px;
        overflow: hidden;
      }

      &-subscript-wrapper {
        display: none;
      }

      &-icon-suffix {
        z-index: 1;

        mat-icon {
          padding: 0;
        }
      }
    }

    &-text-field-wrapper {
      border-radius: 20px;
      overflow: hidden;
    }
  }

  .mdc-notched-outline {
    > div {
      outline: none !important;
      border: none !important;
      background-color: colors.$white !important;
    }
  }
}
