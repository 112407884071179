@use '@angular/material' as mat;

@use 'abstracts/colors';
@use 'abstracts/mixins';

.mat-mdc-form-field.mat-form-field-appearance-outline {
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
    .mat-mdc-input-element:disabled,
    .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
      color: colors.$grey-600;
    }

    .mat-mdc-form-field-infix .mat-mdc-input-element {
      @include mixins.input-placeholder {
        color: colors.$grey-900;
      }
    }
  }

  input:-webkit-autofill {
    &, &:hover, &:focus, &:active {
      -webkit-box-shadow: 0 0 0 30px colors.$grey-300 inset !important;
    }
  }
}
