@use 'abstracts/mixins';
@use 'abstracts/typography';
@tailwind screens;

.app-headline {
  @apply flex items-center justify-start mb-2;

  h1 {
    @include mixins.define-typography(28px, 30px, 700);
    margin-right: 10px;
  }

  h2 {
    @include mixins.define-typography(28px, 30px, 400);
  }

  @screen down-sm {
    h1 {
      @include mixins.define-typography(18px, 24px, 700);
      margin-right: 10px;
    }

    h2 {
      @include mixins.define-typography(18px, 24px, 400);
    }
  }

  @screen down-md {
    flex-direction: column;
  }

  @screen md-and-i14 {
    margin-bottom: 0;

    h1, h2 {
      font-size: typography.$md-andi14-font-size-title;
    }
  }
}
