@use 'abstracts/colors';

.warning {
  background-color: colors.$red-50-alpha-50;
  border: 1px solid colors.$red-500;
}
.linking {
  background-color: colors.$blue-50;
  border: 1px solid colors.$blue-500;
}
.requirement {
  background-color: colors.$orange-100;
  border: 1px solid colors.$orange-500;
}
.fraud {
  background-color: colors.$purple-50;
  border: 1px solid colors.$purple-500;
}
.undefined {
  background-color: colors.$grey-300;
  border: 1px solid colors.$grey-900;
}

.text-warning{
  color: colors.$red-500
}
.text-linking{
  color: colors.$blue-500
}
.text-requirement{
  color: colors.$orange-500
}
.text-fraud{
  color: colors.$purple-500
}
.text-undefined{
  color: colors.$grey-900
}
