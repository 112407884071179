@use 'abstracts/colors';
@tailwind screens;

:root {
  --fc-button-text-color: #000 !important;
  --fc-button-bg-color: #EEBD22 !important;
  --fc-button-border-color: transparent !important;
  --fc-button-hover-bg-color: #EC9D1B !important;
  --fc-button-hover-border-color: transparent !important;
  --fc-button-active-bg-color: #000 !important;
  --fc-button-active-border-color: transparent !important;
}


.fc-header-toolbar {
  .fc-button-active {
    color: colors.$yellow-500 !important;
  }

  .fc-button {
    text-transform: uppercase !important;
    box-shadow: unset !important;
    outline: unset !important;
    border-radius: 4px !important;
  }

  .fc-button {
    margin: 0 4px !important;

    &:last-child {
      margin-right: 0 !important;
    }

    &:first-child {
      margin-left: 0 !important;
    }
  }

  .fc-today-button {
    margin-left: 16px !important;
  }

  .fc-add_event-button {
    margin-left: 20px !important;
    color: colors.$yellow-500 !important;
    background-color: colors.$black !important;;
  }

  .fc-prev-button .fc-icon, .fc-next-button .fc-icon {
    position: relative;
    bottom: 1px;
  }

  @screen down-lg {
    flex-wrap: wrap;
    justify-content: center;

    .fc-toolbar-chunk {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 10px 0;
    }
  }
}

.fc-daygrid-event-harness {
  cursor: pointer;
}

.fc-event.fc-list-event {
  cursor: pointer !important;
}
