@use 'abstracts/colors';

.mark-as-bad-body {
  border: 1px solid colors.$red-500 !important;
  border-radius: 2px;
  color: colors.$white !important;
  background-color: colors.$red-500 !important;
}

.mark-as-bad-body-border {
  border: 1px solid colors.$red-500 !important;
  border-radius: 2px;
}
