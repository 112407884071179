@use '@angular/material' as mat;
@use 'abstracts/typography';
@use 'abstracts/colors';

// taken from "@angular/material/core/style/_variables.scss"
$ease-in-out-curve-function: cubic-bezier(0.35, 0, 0.25, 1);

$default-transition:
  color 100ms $ease-in-out-curve-function,
  background-color 100ms $ease-in-out-curve-function;

.mat-mdc-menu-trigger .mat-mdc-button-persistent-ripple::before {
  display: none;
}

.mat-button-icon-right {
  flex-direction: row-reverse;

  mat-icon {
    margin: 0 0 0 5px !important;
    bottom: 0;
  }
}

.mat-mdc-unelevated-button, .mdc-button--outlined {
  height: fit-content !important;
  padding: 8px 14px !important;
  min-width: unset !important;
  line-height: 20px !important;

  @screen md-and-i14 {
    font-size: typography.$md-andi14-font-size !important;
    padding: 6px 10px !important;
  }
}

// Flat buttons

.mat-mdc-unelevated-button.mat-unthemed:not(:disabled) {
  background-color: colors.$grey-500;

  &:hover {
    background-color: colors.$grey-600;
    color: colors.$black;
    transition: $default-transition;
  }

  &:disabled {
    background-color: rgba(colors.$grey-500, .5);
    color: rgba(colors.$grey-500, .5);
    border-color: rgba(colors.$yellow-500, .5);
  }

  &.active {
    color: colors.$black;
    background-color: colors.$yellow-500;
  }
}

.mat-mdc-unelevated-button.mat-primary {
  border: 1px solid colors.$yellow-500;
  line-height: 18px !important;

  &:not(:disabled) {
    color: colors.$yellow-500;
  }

  &:hover {
    background-color: colors.$yellow-500;
    color: colors.$black;
    transition: $default-transition;
  }

  &:disabled {
    background-color: colors.$black;
    color: rgba(colors.$yellow-500, .5);
    border-color: rgba(colors.$yellow-500, .5);
  }
}

.mat-mdc-unelevated-button.mat-accent {
  &:not(:disabled):hover {
    background-color: colors.$yellow-700;
    color: colors.$black;
    transition: $default-transition;
  }

  &:disabled {
    background-color: rgba(colors.$yellow-500, .5);
    color: rgba(colors.$black, .5);
  }

  &.active {
    color: colors.$black;
    background-color: colors.$yellow-500;
  }
}

.mat-mdc-unelevated-button.mat-warn {
  &:not(:disabled):hover {
    background-color: colors.$red-600;
    color: colors.$white;
    transition: $default-transition;
  }

  &:disabled {
    background-color: rgba(colors.$red-500, .5);
    color: colors.$white;
  }
}

.mat-mdc-unelevated-button.mat-success {
  &:not(:disabled) {
    background-color: colors.$green-500;
    color: colors.$white;

    &:hover {
      background-color: colors.$green-600;
      color: colors.$white;
      transition: $default-transition;
    }
  }

  &:disabled {
    background-color: rgba(colors.$green-500, .5);
    color: colors.$white;
  }
}

// Stroked buttons

.mdc-button--outlined {
  background-color: colors.$white;
  line-height: 18px !important;

  &:hover {
    transition: $default-transition;
  }

  &:disabled {
    color: colors.$grey-500;
    border-color: colors.$grey-500;
  }

  &.mat-unthemed:not(:disabled) {
    border-color: colors.$grey-900;
    color: colors.$grey-900;

    &:hover {
      color: colors.$grey-1000;
      border-color: colors.$grey-1000;
    }
  }

  &.mat-primary {
    &:not(:disabled) {
      color: colors.$black;
      border-color: colors.$black;

      &:hover {
        color: rgba(colors.$black, .8);
        border-color: rgba(colors.$black, .8);
      }
    }

    &:disabled {
      color: rgba(colors.$black, .5);
      border-color: rgba(colors.$black, .5);
    }
  }

  &.mat-accent {
    &:not(:disabled) {
      border-color: colors.$yellow-500;

      &:hover {
        border-color: colors.$yellow-700;
        color: colors.$yellow-700;
      }
    }

    &:disabled {
      color: rgba(colors.$yellow-500, .5);
      border-color: rgba(colors.$yellow-500, .5);
    }
  }

  &.mat-warn {
    &:not(:disabled) {
      border-color: colors.$red-500;

      &:hover {
        color: colors.$red-600;
        border-color: colors.$red-600;
      }
    }

    &:disabled {
      color: rgba(colors.$red-500, .5);
      border-color: rgba(colors.$red-500, .5);
    }
  }

  &.mat-success {
    &:not(:disabled) {
      color: colors.$green-500;
      border-color: colors.$green-500;

      &:hover {
        color: colors.$green-600;
        border-color: colors.$green-600;

        .mat-mdc-button-persistent-ripple::before {
          background-color: colors.$green-100;
        }
      }
    }

    &:disabled {
      color: rgba(colors.$green-500, .5);
      border-color: rgba(colors.$green-500, .5);
    }

    &:active {
      .mat-mdc-button-persistent-ripple::before {
        background-color: colors.$green-200;
      }

      .mat-mdc-button-ripple {
       background-color: rgba(colors.$green-200, .15);
      }
    }
  }
}

.mat-mdc-unelevated-button.mat-sm-button, .mdc-button--outlined.mat-sm-button {
  padding: 6px 8px !important;
  line-height: 14px !important;
  font-size: 12px !important;

  .mat-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px !important;
    width: 12px !important;
    height: 12px !important;
  }

  @screen md-and-i14 {
    font-size: typography.$md-andi14-font-size-sm !important;
    padding: 4px 8px !important;
  }
}

.mdc-button--outlined.mat-sm-button, .mat-mdc-unelevated-button.mat-primary.mat-sm-button {
  line-height: 12px !important;
}

.hide-btn-ripple {
  .mat-mdc-button-persistent-ripple, .mat-mdc-button-touch-target, .mat-ripple {
    display: none !important;
  }
}


@screen down-sm {
  .mat-mdc-unelevated-button, .mat-mdc-unelevated-button.mat-primary, .mdc-button--outlined {
    padding: 6px 8px !important;
    line-height: 14px !important;
    font-size: 12px !important;

    .mat-icon {
      width: 12px !important;
      height: 12px !important;
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
      font-size: 12px !important;
    }
  }

  .mdc-button--outlined, .mat-mdc-unelevated-button.mat-primary {
    line-height: 12px !important;
  }
}
