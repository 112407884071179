@use "abstracts/colors";

$mat-option-height: 42px;

.cdk-scroll-select {
  height: calc(5 * $mat-option-height);
}

cdk-virtual-scroll-viewport {
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    -webkit-border-top-right-radius: 0.2rem;
    -webkit-border-bottom-right-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    // background-color: $black;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 2px;
    border-radius: 3px;
    background: colors.$black;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: colors.$grey-900;
  }
}

@keyframes show {
  99% { opacity: 0 }
  100% { opacity: 1 }
}
