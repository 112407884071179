@use 'abstracts/colors';


.mat-mdc-snack-bar-container  {
  border-radius: 4px;

  .mdc-snackbar__surface {
    background-color: transparent !important;
  }

  .mat-mdc-simple-snack-bar {
    justify-content: center;
  }

  .mdc-snackbar__label {
    text-align: center;
  }
}

.toasty-success{
  background-color: colors.$green-500;
  color: colors.$white;
}

.toasty-failure{
  background-color: colors.$red-500;
  color: colors.$white;
}

.toasty-warning{
  background-color: colors.$yellow-500;
  color: colors.$white;
}

.toasty-info{
  background-color: colors.$black;
  color: colors.$white;
}
