// rewrite Angular 15 switchers new look
@use '@material/switch/switch' as mdc-switch;
@use '@material/switch/switch-theme' as mdc-switch-theme;
@use 'abstracts/colors';

$_color-selected-handle:colors.$black;
$_color-unselected-handle:colors.$grey-500;

$_color-unselected-track: colors.$grey-300;
$_color-selected-track: colors.$grey-600;
$_color-disabled-selected-track: $_color-selected-track;
$_color-disabled-unselected-track: $_color-unselected-track;

.mat-mdc-slide-toggle {

  .mdc-switch {

    @include  mdc-switch-theme.theme((
      //handle color
      disabled-selected-handle-color: $_color-selected-handle,
      disabled-unselected-handle-color: $_color-unselected-handle,

      selected-handle-color: $_color-selected-handle,
      selected-focus-handle-color: $_color-selected-handle,
      selected-hover-handle-color: $_color-selected-handle,
      selected-pressed-handle-color: $_color-selected-handle,

      unselected-handle-color: $_color-unselected-handle,
      unselected-focus-handle-color: $_color-unselected-handle,
      unselected-hover-handle-color: $_color-unselected-handle,
      unselected-pressed-handle-color: $_color-unselected-handle,


      //tracks color
      disabled-selected-track-color: $_color-selected-track,
      disabled-unselected-track-color: $_color-unselected-track,

      selected-track-color: $_color-selected-track,
      selected-focus-track-color: $_color-selected-track,
      selected-hover-track-color: $_color-selected-track,
      selected-pressed-track-color: $_color-selected-track,


      unselected-track-color: $_color-unselected-track,
      unselected-focus-track-color: $_color-unselected-track,
      unselected-hover-track-color: $_color-unselected-track,
      unselected-pressed-track-color: $_color-unselected-track,
      // icon colors

      disabled-selected-icon-color:  $_color-selected-handle,
      disabled-unselected-icon-color: $_color-unselected-handle,
      selected-icon-color: $_color-selected-handle,
      unselected-icon-color: $_color-unselected-handle,

    ));

  }
}

mat-slide-toggle {
  .mdc-switch__ripple {
    display: none;
  }

  label {
    cursor: pointer;
    user-select: none;
  }
}
