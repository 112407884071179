@use 'abstracts/colors';

$checkbox-size: 18px;

.mat-mdc-checkbox {
  .mdc-checkbox {
    margin: 0 !important;
    padding: 0 !important;

    .mat-mdc-checkbox-touch-target, .mdc-checkbox__native-control {
      height: $checkbox-size !important;
      width: $checkbox-size !important;
    }

    .mdc-checkbox__background {
      left: 0 !important;
      top: 0 !important;
    }

  }

  .mdc-form-field > label {
    padding-left: 8px;
  }

  &.mat-accent .mdc-checkbox__checkmark, &.mat-primary .mdc-checkbox__checkmark {
   color: colors.$white !important;
  }

  .mdc-checkbox__ripple {
    display: none;
  }

  &.checkbox-outline .mdc-checkbox__background {
    background-color: transparent !important;
  }

  &.checkbox-outline .mdc-checkbox__checkmark {
    color: colors.$yellow-500 !important;
  }

  &.checkbox-unselected-white:not(.mat-mdc-checkbox-checked) .mdc-checkbox__background {
    border-color: colors.$white !important;
  }
}
