@use "abstracts/colors";
@use "abstracts/mixins";
@use 'abstracts/typography';

.filters-container {
  @apply p-5;
}

.elastic-table-container {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    -webkit-border-top-right-radius: 0.2rem;
    -webkit-border-bottom-right-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 2px;
    border-radius: 3px;
    background: colors.$black;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: colors.$grey-900;
  }

  .elastic-table {
    width: 100%;
    border-collapse: separate;

    .mat-mdc-header-row {
      height: 46px;

      .mat-mdc-header-cell {
        color: colors.$black;
        border-bottom: 1px solid colors.$black;

        @include mixins.define-typography(
          $font-size: 14px,
          $font-weight: 700,
          $line-height: 19px,
        );

        @screen md-and-i14 {
          font-size: typography.$md-andi14-font-size;
        }
      }
    }

    .mat-mdc-row {
      height: 50px;

      &:nth-child(even) {
        background-color: colors.$grey-300-alpha-50;

        .mat-mdc-table-sticky {
          background-color: colors.$grey-300-no-alpha;
        }
      }

      &:nth-child(odd) .mat-mdc-table-sticky {
        background-color: colors.$white;
      }

      .mat-mdc-cell {
        color: colors.$black;
        border-bottom-width: unset;
        border-bottom-style: unset;

        @include mixins.define-typography(
          $font-size: 14px,
          $font-weight: 400,
          $line-height: 18px,
        );

        @screen md-and-i14 {
          font-size: 12px;
        }
      }
    }
  }

  .mat-mdc-header-cell, .mat-mdc-cell {
    padding: 10px;
  }

  mat-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
  }

  .mat-mdc-footer-cell {
    font-weight: bold;
    padding: 10px;
  }
}
