$white: #FFFFFF;
$white-alpha-10: rgba(255, 255, 255, 0.1);
$white-alpha-20: rgba(255, 255, 255, 0.2);
$grey-300: #EEEEEE;
$grey-300-no-alpha: #f2f2f2;
$grey-300-alpha-40: rgba(238, 238, 238, 0.4);
$grey-300-alpha-50: rgba(238, 238, 238, 0.5);
$grey-500: #E0E0E0;
$grey-600: #BDBDBD;
$grey-900: #757575;
$grey-1000: #616060;
$black: #000000;
$black-blur: rgba(0, 0, 0, 0.32);
$black-backdrop: rgba(0, 0, 0, 0.8);
$black-alpha-300: rgba(190, 147, 190, 0.1);
$black-300: #432843;
$yellow-500: #EEBD22;
$yellow-700: #EC9D1B;
$grey-blue-50: #F3FAFE;
$grey-blue-500: #6E838C;
$orange-50: #FFF2D8;
$orange-100: #F0B51819;
$orange-500: #FFA726;
$orange-600: darkorange;
$orange-600-alpha-100: rgba(255, 175, 77, 0.1);
$red-50-alpha-50: #FFECEC;
$red-50: #dd5826;
$red-500: #FF1744;
$red-500-alpha-50: rgba(255, 23, 68, 0.5);
$red-600: #C61653;
$green-50: #E7FFF1;
$green-100: #3dd541;
$green-200: #2fb733;
$green-500: #4CAF50;
$green-600: #3C955B;
$blue-50: #5D8FC219;
$blue--alpha-10: #5DC4BF19;
$blue-50: #EDF1FD;
$blue-200: #5dc4bf;
$blue-500: #6F8EFF;
$blue-600: #5076FF;
$purple-50: #F4E1FD;
$purple-500: #C54AFF;

//$white: var(--white);
//$white-alpha-10: var(--white-alpha-10);
//$white-alpha-20: var(--white-alpha-20);
//$grey-300: var(--grey-300);
//$grey-300-alpha-40: var(--grey-300-alpha-40);
//$grey-300-alpha-50: var(--grey-300-alpha-50);
//$grey-500: var(--grey-500);
//$grey-600: var(--grey-600);
//$grey-900: var(--grey-900);
//$black: var(--black);
//$yellow-500: var(--yellow-500);
//$yellow-700: var(--yellow-700);
//$grey-blue-50: var(--grey-blue-50);
//$grey-blue-500: var(--grey-blue-500);
//$orange-50: var(--orange-50);
//$orange-500: var(--orange-500);
//$red-50: var(--red-50);
//$red-500: var(--red-500);
//$red-500-alpha-50: var(--red-500-alpha-50);
//$red-600: var(--red-600);
//$green-50: var(--green-50);
//$green-500: var(--green-500);
//$green-600: var(--green-600);
