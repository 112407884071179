@use 'abstracts/colors';

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-top-right-radius: 0.2rem;
  -webkit-border-bottom-right-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 3px;
  background: colors.$black;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: colors.$grey-900;
}

// Hide tinymce editor alert
.tox.tox-silver-sink.tox-tinymce-aux {
  display: none;
}

body {
  position: relative;
}

.alerts-list, .activity-list {
  ul, ol {
    padding-left: 0;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
}

.ul-disc-parent {
  ul {
    margin-left: 15px;
    list-style: disc;
  }
}

.ol-disc-parent {
  ol {
    margin-left: 15px;
    list-style: auto;
  }
}

.reset-paragraph-style p {
  margin: 0;
}

.account-search .elastic-table-container {
  max-height: calc(100vh - 450px);
}

.create-discount-dialog .dialog-frame-body  {
  padding: 0;
}

.image-preview-dialog-container .mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: colors.$black-backdrop;
  overflow: hidden;
}

quill-view-html .ql-snow .ql-editor {
  padding: 0;

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
}

mat-error {
  color: colors.$red-500;
}

:root {
  --mdc-outlined-text-field-outline-width: 0;
  --mdc-outlined-text-field-focus-outline-width: 0;
}
