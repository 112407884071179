app-dialog-frame, .custom-dialog-content {
  .dialog-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .close-btn {
    line-height: 0 !important;
  }
}
