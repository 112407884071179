@tailwind screens;

.photo-viewer-dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: transparent;
    box-shadow: unset;
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  @screen down-xl {
    max-width: 98vw !important;
  }
}

.info-dialog-text-container {
  .header-title {
    word-break: break-word;
  }
}
