@use 'abstracts/typography';

@mixin input-placeholder {
  &::placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }

  // Note: this isn't necessary anymore since we don't support
  // IE, but it caused some presubmit failures in #23416.
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin define-typography(
  $font-size,
  $line-height: $font-size,
  $font-weight: 400,
  $font-family: typography.$font-family,
  $letter-spacing: normal
) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
  font-family: $font-family;
  letter-spacing: $letter-spacing;
}

@mixin borderSeparator($list, $border-dir, $px, $color) {
  @each $item in $list {
    &:nth-child(#{$item}) {
      border-#{$border-dir}: #{$px} solid $color;
    }
  }
}

@mixin filterGrid($minWidth) {
  $grid-layout-gap: 90px;
  $grid-column-count: 3;
  $grid-item--min-width: $minWidth;

  /**
   * Calculated values.
   */
  $gap-count: calc(#{$grid-column-count} - 1);
  $total-gap-width: calc(#{$gap-count} * #{$grid-layout-gap});
  $grid-item--max-width: calc((100% - #{$total-gap-width}) / #{$grid-column-count});

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(#{$grid-item--min-width}, #{$grid-item--max-width}), 1fr));
  grid-gap: $grid-layout-gap;
  grid-row-gap: 0;
}
