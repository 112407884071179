@use 'abstracts/colors';

.mat-mdc-form-field.mat-form-field-appearance-outline {

  .mdc-text-field--outlined .mdc-notched-outline {
    z-index: unset;
  }

  .mdc-text-field {
    padding: 0 10px;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
    height: 40px;

    .mat-mdc-notch-piece {
      border-width: var(--mdc-outlined-text-field-focus-outline-width);
    }

    .mdc-notched-outline {
      div[class^="mat-mdc-notch-piece"] {
        background-color: colors.$grey-300;
        border-style: none;
      }
    }


    .mat-mdc-form-field-infix {
      border-top: 0;
      height: 100%;
      min-height: auto;
      padding: 1px; // for autocomplete="off"

      .mat-mdc-input-element,
      .mdc-evolution-chip-set__chips .mat-mdc-input-element {
        height: 100%;
        margin: 0;
      }

      .mat-mdc-select {
        display: block;
        height: 100%;

        .mat-mdc-select-trigger {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;

          .mat-mdc-select-value {
            max-width: unset;

            &:first-letter {
              text-transform: capitalize;
            }
          }
        }
      }

      .mat-form-field-label-wrapper {
        display: none;
      }
    }

    .mat-mdc-form-field-infix .mat-mdc-chip-grid {
      padding: 0;
    }
  }

  //.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
  //  display: none;
  //}
  .mat-mdc-form-field-subscript-wrapper,
  .mat-mdc-form-field-error-wrapper {
    margin-top: 0;
    padding: 0 8px;

    .mat-mdc-form-field-error {
      font-weight: 400;
      //margin-top: 4px;
    }
  }

  &.mat-form-field-disabled {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
      .mdc-notched-outline {
        div[class^="mat-mdc-notch-piece"] {
          background-color: colors.$grey-300-alpha-50;
        }
      }
    }
  }

  &.mat-form-field-invalid {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
      //.mdc-notched-outline.mat-form-field-outline-thick {
      .mdc-notched-outline {
        .mdc-notched-outline__leading, .mdc-notched-outline__trailing {
          border-color: colors.$red-500-alpha-50;
          border-style: solid;
        }

        .mdc-notched-outline__leading {
          border-width: 1px 0 1px 1px;
          border-radius: 4px 0 0 4px;
        }

        .mdc-notched-outline__trailing {
          border-width: 1px 1px 1px 0;
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }

  &:not(.mat-form-field-invalid) {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
      //&:hover, &:focus-within {
      &:hover, &:focus {
        //.mdc-notched-outline.mat-form-field-outline-thick {
        .mdc-notched-outline {
          .mdc-notched-outline__leading, .mdc-notched-outline__trailing {
            border-color: colors.$yellow-500;
            border-style: solid;
          }

          .mdc-notched-outline__leading {
            border-width: 1px 0 1px 1px;
            border-radius: 4px 0 0 4px;
          }

          .mdc-notched-outline__trailing {
            border-width: 1px 1px 1px 0;
            border-radius: 0 4px 4px 0;
          }
        }
      }
    }
  }
  .mat-mdc-select-arrow-wrapper {
    transform: translateY(0);
  }
}

.textarea-field.mat-mdc-form-field.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  height: 100%;
  padding: 0;

  textarea {
    padding: 0 .75em;
    box-sizing: border-box;
  }
}

.no-padding-and-margin .mat-mdc-text-field-wrapper {
  padding: 0;
  margin: 0;
}

mat-optgroup .mat-mdc-optgroup-label:first-letter, .mat-option-text:first-letter {
  text-transform: capitalize;
}

.mat-mdc-form-field-error {
  display: inline-block !important;
}

.first-letter-capital:first-letter {
  text-transform: uppercase;
}

.form-field-outlined {
  .mdc-notched-outline {
    &>* {
      border: unset !important;
      outline: unset !important;
      background: transparent !important;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0 !important;
  }

  .mat-mdc-form-field-infix {
    width: fit-content !important;
    margin-right: 20px;
  }
}

.select-outlined {
  .mat-mdc-select-arrow {
    svg {
      display: none;
    }
  }

  .mat-mdc-select-value-text, .mat-mdc-select-placeholder {
    border-bottom: 1px solid black;
    font-weight: bold;
    display: inline-flex;
    align-items: center;

    //.mat-mdc-select-min-line {
    //  display: inline-block;
    //  max-width: 140px;
    //  overflow: hidden;
    //  text-overflow: ellipsis;
    //  white-space: nowrap;
    //}

    &:after{
      content: '';
      display: inline-block;
      width: 12px;
      height: 7px;
      background-image: url('/assets/images/arrow-down.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 10px;
    }
  }

  .mat-mdc-select-placeholder {
    font-weight: normal;
  }
}

.hide-input-subscript-wrapper .mat-mdc-form-field-subscript-wrapper {
  display: none!important;
}

.mat-mdc-form-field-icon-prefix {
  display: flex;
  align-items: center;
}

app-multi-select {
  position: relative;
}

.disable-subscript-wrapper {
  margin-bottom: 21px !important;

  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    display: none !important;
  }
}

.like-multi-selector {
  .mat-mdc-select-value-text .mat-mdc-select-min-line {
    border-radius: 4px;
    background-color: colors.$yellow-500;
    padding: 2px 12px;
  }
}

.multi-line-error.mat-mdc-form-field {
  .mat-mdc-form-field-subscript-wrapper {
    min-height: 22px !important;

    &:before {
      display: none !important;
    }
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
    position: static !important;
  }
}

.mat-mdc-option .mat-pseudo-checkbox-minimal {
  margin-left: 0 !important;
}

.mat-pseudo-checkbox {
  width: 8px !important;
}

.hidden-mat-option.mat-mdc-option.mdc-list-item {
  display: none !important;
}

app-multi-select .mat-mdc-chip-remove {
  overflow: hidden;
}
