@use '@angular/material' as mat;

$font-family: var(--font-family);
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-normal: 400;
$font-weight-thin: 300;
$md-andi14-font-size: 12px;
$md-andi14-font-size-sm: 10px;
$md-andi14-font-size-title: 24px;

$typography-config: mat.m2-define-typography-config(
  $font-family:   $font-family, // 'Roboto, "Helvetica Neue", sans-serif'
  $headline-1:     mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2:     mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3:     mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4:     mat.m2-define-typography-level(34px, 40px, 400),
  $headline-5:      mat.m2-define-typography-level(36px, 49.03px, 700),
  $headline-6:         mat.m2-define-typography-level(20px, 32px, 500),
  $subtitle-1:  mat.m2-define-typography-level(16px, 28px, 400),
  $body-1:  mat.m2-define-typography-level(15px, 24px, 400),
  $subtitle-2:        mat.m2-define-typography-level(14px, 24px, 500),
  $body-2:        mat.m2-define-typography-level(14px, 20px, 400),
  $caption:       mat.m2-define-typography-level(12px, 20px, 400),
  $button:        mat.m2-define-typography-level(14px, 20px, 700), // changed
);
