@use 'sass:map';

@use '@angular/material' as mat;

@use '../abstracts/colors';
@use '../abstracts/palettes';
@use '../abstracts/typography';
@use './background-foregraund' as bg-fg;

:root {
  --font-family: 'Open Sans', sans-serif;
  --white: #FFFFFF;
  --white-alpha-10: rgba(255, 255, 255, 0.1);
  --white-alpha-20: rgba(255, 255, 255, 0.2);
  --grey-300: #EEEEEE;
  --grey-300-alpha-40: rgba(238, 238, 238, 0.4);
  --grey-300-alpha-50: rgba(238, 238, 238, 0.5);
  --grey-500: #E0E0E0;
  --grey-600: #BDBDBD;
  --grey-900: #757575;
  --black: #000000;
  --yellow-500: #EEBD22;
  --yellow-700: #EC9D1B;
  --grey-blue-50: #F3FAFE;
  --grey-blue-500: #6E838C;
  --orange-50: #FFF2D8;
  --orange-500: #FFA726;
  --red-50: #FFECEC;
  --red-500: #FF1744;
  --red-500-alpha-50: rgba(255, 23, 68, 0.5);
  --red-600: #C61653;
  --green-50: #E7FFF1;
  --green-500: #4CAF50;
  --green-600: #3C955B;
}

$primary-palette: mat.m2-define-palette((
  100: colors.$white,
  500: colors.$black,
  700: colors.$black,
  contrast: (
    100: colors.$black,
    500: colors.$yellow-500,
    700: colors.$white,
  )
));

$accent-palette: mat.m2-define-palette((
  100: colors.$white,
  500: colors.$yellow-500,
  700: colors.$black,
  contrast: (
    100: colors.$black,
    500: colors.$black,
    700: colors.$white,
  )
));

$warn-palette: mat.m2-define-palette((
  100: colors.$white,
  500: colors.$red-500,
  700: colors.$black,
  contrast: (
    100: colors.$black,
    500: colors.$white,
    700: colors.$white,
  )
));

$theme: (
  primary: $primary-palette,
  accent: $accent-palette,
  warn: $warn-palette,
  is-dark: false,
  background: bg-fg.$light-theme-background-palette,
  foreground: bg-fg.$light-theme-foreground-palette,
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(typography.$typography-config);`
@include mat.all-component-typographies(typography.$typography-config);
@include mat.elevation-classes();
@include mat.app-background();

@include mat.all-component-themes((
  color: $theme,
));

// @include mat.button-theme(components.$button-theme);
// @include mat.form-field-theme(components.$form-field-theme);
// @include mat.input-theme(components.$input-theme);
