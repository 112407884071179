@tailwind screens;

$warning-icon-size: 50px !important;

app-dialog-frame {
  .info-dialog-text-container {
    max-width: calc(100% - 130px);

    @screen down-md {
      max-width: calc(100% - 80px);
    }
  }


  @screen down-md {
    .waring-icon {
      min-width: $warning-icon-size;
      min-height: $warning-icon-size;
      height: $warning-icon-size;
      width: $warning-icon-size;
      font-size: 30px !important;
    }
  }
}
